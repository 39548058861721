@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100;9..40,200;9..40,300;9..40,400;9..40,500;9..40,600;9..40,700;9..40,800;9..40,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Caveat&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  font-family: "Inter", sans-serif;
}

@layer components {
  .blade-top-padding {
    @apply pt-8 md:pt-10 lg:pt-12 xl:pt-16 2xl:pt-20;
  }

  .blade-top-padding-sm {
    @apply pt-4 md:pt-6 lg:pt-8 xl:pt-10 2xl:pt-14;
  }

  .blade-top-padding-lg {
    @apply pt-10 md:pt-12 lg:pt-16 xl:pt-20 2xl:pt-24;
  }

  .blade-top-padding-xl {
    @apply pt-16 xl:pt-20 2xl:pt-32;
  }

  .blade-bottom-padding {
    @apply pb-8 md:pb-10 lg:pb-12 xl:pb-16 2xl:pb-20;
  }

  .blade-bottom-padding-sm {
    @apply pb-4 md:pb-6 lg:pb-8 xl:pb-10 2xl:pb-14;
  }

  .blade-bottom-padding-lg {
    @apply pb-10 md:pb-12 lg:pb-16 xl:pb-20 2xl:pb-24;
  }

  .blade-bottom-padding-xl {
    @apply pb-16 xl:pb-20 2xl:pb-32;
  }

  .w-container-sm {
    @apply max-w-[1366px] mx-auto sm:w-11/12 md:w-10/12 px-3;
  }

  .w-container {
    @apply max-w-screen-2xl mx-auto md:w-11/12 lg:w-10/12 px-3;
  }

  .w-container-lg {
    @apply md:w-11/12 px-3 max-w-screen-2xl mx-auto;
  }

  .w-container-xl {
    @apply md:w-11/12 px-3 xl:px-16 2xl:px-20 max-w-[1920px] mx-auto;
  }
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .timeline-elem {
    border: 1px solid #444;
    background: linear-gradient(
      95deg,
      rgba(137, 0, 255, 0.15) 0%,
      rgba(238, 22, 125, 0.15) 100%
    );
  }

  .timeline-divider::after {
    top: calc(180%);
  }

  .text-shadow-md {
    text-shadow: -2.136px -2.136px 0px rgba(93, 53, 238, 1);
  }
  .text-shadow-sm {
    text-shadow: -2.757px -2.757px 0px #5d35ee;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .home_banner_text_gradient {
    background: linear-gradient(
      90deg,
      #9058ff 0%,
      #ff6eb2 34.5%,
      #ffb562 68%,
      #ffe8b1 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .home_banner_square_desktop {
    border-radius: 4.44513rem 4.44513rem 0rem 0rem;
    background: linear-gradient(180deg, #ffc162 0%, #e07919 100%);
    box-shadow: -7.409px 0px 37.043px 0px rgba(0, 0, 0, 0.4);
  }

  .home_banner_square_phone {
    border-radius: 4.44513rem 4.44513rem 0rem 0rem !important;
    background: linear-gradient(180deg, #ffc162 0%, #e07919 100%) !important;
    box-shadow: -7.409px 0px 37.043px 0px rgba(0, 0, 0, 0.4) !important;
  }

  .home_banner_bar_phone {
    border-radius: 3.56444rem 0rem 0rem 3.56444rem !important;
    background: linear-gradient(90deg, #7d69ff 0%, #5745cb 100%) !important;
  }

  .home_banner_bar_desktop {
    border-radius: 5.2815rem 0rem 0rem 5.2815rem !important;
    background: linear-gradient(90deg, #7d69ff 0%, #5745cb 100%) !important;
  }

  .home_banner_name_desktop {
    border-radius: 1.2965rem;
    background: #fff;
    box-shadow: 2.963px 2.963px 17.78px 0px rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    width: 17.13225rem;
    padding: 0.74088rem 1.85213rem;
    align-items: center;
    gap: 0.92606rem;
  }

  .home_banner_name_phone {
    border-radius: 0.875rem;
    background: #fff;
    box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    width: 11.5625rem;
    padding: 0.5rem 1.25rem;
    align-items: center;
    gap: 0.625rem;
  }

  .home_banner_image_desktop {
    transform-origin: bottom right;
  }

  .stat-gradient {
    color: white;
  }

  .stat-back {
    background: #1c0d29;
    border-radius: 16px;
    border: 1px #433b49 solid;
    backdrop-filter: blur(30px);
  }

  .stats-inner {
    background: #411169;
    border-radius: 16px;
    border: 1px #971eff solid;
  }

  .clients-swiper .swiper-wrapper {
    transition-timing-function: linear !important;
  }

  .clients-swiper .swiper::after {
    content: "";
    background-image: linear-gradient(
      to right,
      #14091e 5%,
      transparent 25%,
      transparent 75%,
      #14091e 95%
    );
    z-index: 10;
    position: absolute;
    inset: 0;
  }

  .gsapHeroPage {
    overflow-y: scroll;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    overflow-y: visible;
    position: relative;
    height: unset;
  }

  .why_youvah_border {
    border-radius: 1.5rem;
    border: 2.2px solid rgba(222, 183, 255, 0.22);
  }

  .testimonial-card {
    border: 1.5px solid #7a7a7a;
    background: rgba(255, 255, 255, 0.1);
  }

  .master-btn {
    background: linear-gradient(270deg, #5a258e 0%, #971eff 100%);
  }

  .middle-blur {
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    background: hsla(0, 0%, 100%, 0.1);
    border: 1px solid #7a7a7a;
    border-radius: 1rem;
    padding: 14px;
  }

  .sidebar_active {
    /* border-right: 4px solid white; */
  }

  .swiper-slide {
    user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    -webkit-user-select: text;
  }

  .back-gradied {
    background: linear-gradient(
      0deg,
      #971eff 6%,
      #150f5d 46%,
      #09063e 71%,
      #000028 100%
    );
  }

  .line-grade {
    background: linear-gradient(90deg, #971eff 0%, rgba(151, 30, 255, 0) 100%);
  }

  .sidebar_box {
    box-shadow: 2px 1px 12px 11px rgba(177, 144, 212, 1) inset;
  }

  .tailwingSidebar {
    height: calc(100dvh - 4.5rem);
    /* Add any other styles for the tailwing here */
  }
}

@layer base {
  h1 {
    @apply text-4xl lg:text-5xl 2xl:text-6xl md:leading-snug lg:leading-snug 2xl:leading-snug;
  }

  h2 {
    @apply text-3xl lg:text-4xl 2xl:text-5xl md:leading-snug lg:leading-snug 2xl:leading-snug;
  }

  h3 {
    @apply text-2xl lg:text-3xl 2xl:text-4xl md:leading-snug lg:leading-snug 2xl:leading-snug;
  }

  h4 {
    @apply text-xl lg:text-2xl 2xl:text-3xl leading-tight md:leading-snug lg:leading-snug 2xl:leading-snug;
  }

  h5 {
    @apply text-lg lg:text-xl 2xl:text-2xl leading-normal md:leading-normal lg:leading-normal 2xl:leading-normal;
  }

  h6 {
    @apply text-base lg:text-lg 2xl:text-xl leading-normal md:leading-normal lg:leading-normal 2xl:leading-normal;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #040418;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #040418;
}

::-webkit-scrollbar-track {
  background-color: #d8d8d8;
}

::-webkit-scrollbar-track:hover {
  background-color: #d8d8d8;
}

/* remove blue active state on mobile*/
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
