:root {
  --color-1: 0 100% 63%;
  --color-2: 270 100% 63%;
  --color-3: 210 100% 63%;
  --color-4: 195 100% 63%;
  --color-5: 90 100% 63%;
}
.loader-div{
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index:  19999;
  letter-spacing: 3px;
  font-size: 1.5rem;
  color: white;
  background-color: #191919;
  transition: opacity 0.2s;
}
.loader-title {
  position: absolute;
  top: 38%;
  left: 50%;
  width: 85%;
  transform: translate(-50%);
  display:flex;
  flex-direction:column;
  text-align:center;
  align-items:center;
}
.loader-para{
  font-size: 18px;
  margin-top: 8px;
}
.loader {
width: 100px;
height: 100px;
position: relative;
display: flex;
justify-content: center;
}
.loader img{
margin-top: 34px;
height: fit-content;
width: 35px;
height: 35px;
}

.loader::before {
content: "";
box-sizing: border-box;
position: absolute;
width: 100px;
height: 100px;
left: 0;
border-radius: 50%;
border-top: 4px solid #8900FF;
border-right: 4px solid transparent;
animation: spinner8217 0.8s linear infinite;
}

@keyframes spinner8217 {
to {
 transform: rotate(360deg);
}
}

.dots-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.dot {
  height: 15px;
  width: 15px;
  margin-right: 10px;
  border-radius: 10px;
  background-color: #971eff;
  animation: pulse 1.5s infinite ease-in-out;
}

.dot:last-child {
  margin-right: 0;
}

.dot:nth-child(1) {
  animation-delay: -0.3s;
}

.dot:nth-child(2) {
  animation-delay: -0.1s;
}

.dot:nth-child(3) {
  animation-delay: 0.1s;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    background-color: #971eff;
    box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
  }

  50% {
    transform: scale(1.2);
    background-color: #9018cc;
    box-shadow: 0 0 0 10px rgba(178, 212, 252, 0);
  }

  100% {
    transform: scale(0.8);
    background-color: #971eff;
    box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
  }
}



.loader-stripe {
  position: relative;
  display: block;
  color: transparent;
  opacity: 0.1;
  background-color: #637282;
  height: 15px;
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -100px;
    opacity: 1;
    transform: rotate(10deg);
    background: rgba(255, 255, 255, 0.13);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
    animation-name: slide;
    animation-delay: 1s;
    animation-duration: 0.8s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
    animation-direction: normal;
  }
}
footer {
  background-image: radial-gradient(70% 70% at top center, #300d4d, #14081e),
    linear-gradient(to bottom, #14081e, #14081e);
  background-position:
    top center,
    left top;
  background-repeat: no-repeat;
  background-size: cover;
}

@keyframes slide {
  0% {
    left: -100px
  }
  100% {
    left: 100px
  }
}
[class^="grid-"] {
  display: grid;
  gap: 1.2em;
}

@media (max-width: 512px) {
  [class^="grid-"] {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (min-width: 513px) {
  [class^="grid-"] > * {
    grid-column: span 2;
  }
}
@media (min-width: 513px) and (max-width: 976px) {
  .grid-4 {
    grid-template-columns: repeat(4, 1fr);
  }

  .grid-4 > *:last-child:nth-child(odd) {
    grid-column-end: 4;
    /* background-color: #842bb0; */
  }
}

@media (min-width: 977px) and (max-width: 1025px) {
  .grid-4 {
    grid-template-columns: repeat(6, 1fr);
  }
  .grid-4 > *:last-child:nth-child(3n-2) {
    grid-column-end: 5;
    /* background-color: #842bb0; */
  }
  .grid-4 > *:last-child:nth-child(3n-1) {
    grid-column-end: 6;
    /* background-color: #842bb0; */
  }
  .grid-4 > *:nth-last-child(2):nth-child(3n + 1) {
    grid-column-end: 4;
    /* background-color: #842bb0; */
  }
}
@media (min-width: 1025px) {
  .grid-4 {
    grid-template-columns: repeat(8, 1fr);
  }
  .grid-4 > *:last-child:nth-child(4n-3) {
    grid-column-end: 6;
    /* background-color: #842bb0; */
  }
  .grid-4 > *:last-child:nth-child(4n-2) {
    grid-column-end: 7;
    /* background-color: #842bb0; */
  }
  .grid-4 > *:nth-last-child(2):nth-child(4n + 1) {
    grid-column-end: 5;
    /* background-color: #842bb0; */
  }
  .grid-4 > *:last-child:nth-child(4n-1) {
    grid-column-end: 8;
    /* background-color: #842bb0; */
  }
  .grid-4 > *:nth-last-child(3):nth-child(4n + 1) {
    grid-column-end: 4;
    /* background-color: #842bb0; */
  }
}

.acourse-lesson-scroll {
  overflow-y: auto;
}

.acourse-lessons-overflow {
  overflow-y: hidden;
}

.form-control{
  background: #1a2734 !important;
    border: 1px solid #7b7b7b !important;
    border-radius: 8px !important;
    color: #fcfcfc !important;
    width: 100% !important;
    height: 50px !important;
    font-size: 16px !important;    
}

.country-list{
  background: #1a2734 !important;
  border: 1px solid #7b7b7b !important;
  border-radius: 8px !important;
  color: #fcfcfc !important;
  -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;
}

.react-tel-input{
  width: 100% !important;
}
.flag-dropdown{
  background: #1a2734 !important;
  border: 1px solid #7b7b7b !important;
}
#pop-modal {
  background: rgba(21, 22, 24, 0.3);
  backdrop-filter: blur(10px);

  height: 100vh;
  height: 100svh !important;
}

.dropdown {
  position: relative;
  display: inline-block;
  border-radius: "5px";
}

.dropdown-content {
  display: none;
  position: absolute;
  font-family: "primaryBold";
  background-color: rgba(255,255,255,1);
  min-width: 160px;
  right: 0;
  border-radius: 5px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  border-radius: 5px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  
  background-color: #971eff ;
  color: white;
}

.dropdown:hover .dropdown-content {display: block;}
.tooltip {
  visibility: hidden;
  width: 150px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  font-size: 14px;
  left: -116px;
    top: 34px;
  border-radius: 6px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

.lms:hover .tooltip {
  visibility: visible;
}

.vimeo-video-wrapper {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative
}

.vimeo-video-wrapper iframe {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%
}

.line-grade{
  background: linear-gradient(90deg, #971EFF 0%, rgba(151, 30, 255, 0) 100%)
 }

 .test-overall{
  display: flex !important;
  justify-content: center !important;
}
.testflex{
  display: flex !important;
  width:390px !important;
}

.siptestimonialcard::after {
  content: '';
  border-radius: 100% 70% 100% 0; 
  background: #bb00bf;
  position: absolute;
  width: 360px;
  height: 275px;
  z-index: -10;
  top: 106px;
  left: 11px;
}

.siptestimonialcard1::after {
  border-radius: 100% 70% 100% 0; 
  background: #bb00bf;
  position: absolute;
  width: 360px;
  height: 275px;
  z-index: -10;
  top: 106px;
  left: 11px;
}

  .siptestimonialcard::after {
  content: '';
  border-radius: 100% 70% 100% 0; 
  background: #bb00bf;
  position: absolute;
  width: 360px;
  height: 275px;
  z-index: -10;
  top: 177px;
  left: 11px;
}
.quarter-ellipse1 { border-radius: 100% 70% 100% 0; }
.quarter-ellipse { border-radius: 16px; }

.timeline-elem {
  border: 1px solid #444;
  background: #210f30;
}
.line-grade{
 background: linear-gradient(90deg, #971EFF 0%, rgba(151, 30, 255, 0) 100%)
}
.timeline-line{

  top: 24%;
  position: absolute;
border: none;
border-left: 5px dashed #F5D66A;
width: 8px;
}
.test-question{
  background: linear-gradient(to right,#2a1f33,#160a20);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
border-width: 0.5px;
  border-style: solid;
  border-image: linear-gradient(to right,#362b3e , #bdb5d2) 1;
  background: linear-gradient(to right,#2a1f33,#160a20) padding-box,
           linear-gradient(to right,#362b3e , #bdb5d2) border-box;
}
.test-answer{
  background: linear-gradient(to right,#2a1f33,#160a20);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
margin-top: 10px;
border-width: 0.5px;
padding: 10px;
  border-style: solid;
  border-image: linear-gradient(to right,#362b3e , #bdb5d2) 1;
  background: linear-gradient(to right,#2a1f33,#160a20) padding-box,
           linear-gradient(to right,#362b3e , #bdb5d2) border-box;
}
#form-modal {
  background: rgba(21, 22, 24, 0.3);
  backdrop-filter: blur(10px);

  height: 100vh;
  height: 100svh !important;
}

.sip .form-control{
  background: #151618 !important;
    border: 1px solid #7b7b7b !important;
    border-radius: 8px !important;
    color: #fcfcfc !important;
    width: 100% !important;
    height: 50px !important;
    font-size: 16px !important;    
}

.sip .country-list{
  background: #151618 !important;
  border: 1px solid #7b7b7b !important;
  border-radius: 8px !important;
  color: #fcfcfc !important;
  -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;
}

 .sip .react-tel-input{
  width: 100% !important;
}
.sip .flag-dropdown{
  background: #151618 !important;
  border: 1px solid #7b7b7b !important;
}
.premium-pricing {
  background:
    linear-gradient(to bottom, black, black) content-box,
    linear-gradient(180deg, #971eff 0%, #4f0e88) border-box;
}
.premium-pricing .highlight {
  background-image: linear-gradient(135deg, #971eff, #4f0e88);
}
.code-wrapper {
  border-radius: 4px;
  border: 2px dashed #13b37a;
  background: rgba(19, 179, 122, 0.15);
}
.newProfile .form-control{
  background: #21132c !important;
    border: 1px solid #7b7b7b !important;
    border-radius: 8px !important;
    color: #fcfcfc !important;
    width: 100% !important;
    height: 50px !important;
    font-size: 16px !important;    
}

.newProfile .country-list{
  background: #21132c !important;
  border: 1px solid #7b7b7b !important;
  border-radius: 8px !important;
  color: #fcfcfc !important;
  -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;
}

 .newProfile .react-tel-input{
  width: 100% !important;
}
.newProfile .flag-dropdown{
  background: #21132c !important;
  border: 1px solid #7b7b7b !important;
}
.flex-swier{
  display: flex !important;
}

.mobile-phone {
  margin: auto;
  /* margin-top: 170px; */
  padding: 10px 10px 30px;
  width: 350px;
  height: 600px;
  box-shadow: 0 0 2px #e2e2e2;
  border-radius: 30px;
  /* width: fit-content; */
}

.screen {
  width: 100%;
  height: 100%;
  background: #f2f2f2;
  border-radius: 30px;
  overflow-y: auto;
}

.brove {
  width: 150px;
  height: 20px;
  background: black;
  position: absolute;
  margin: 0 100px;
  border-radius: 0 0 20px 20px;
  top: 10;
}

.speaker {
  width: 60px;
  height: 5px;
  background: #d2d2d2;
  display: block;
  margin: auto;
  margin-top: 5px;
  border-radius: 20px;
}
.screen::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.screen {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.team{
  background: linear-gradient(180deg, #D5F 0%, #681594 60%, #400077 100%);
}

.terms-wrapper {
  display: flex;
  flex-direction: column;
  padding: 3rem 7%;
  text-align: justify;
}

.terms-logocontainer {
  width: 15%;
  height: auto;
  padding-bottom: 2rem;
  min-width: 100px;
}

.terms-logocontainer>img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
}

.h1-heading-legal {
  /* font-family: primaryBold; */
  font-weight: 900;
  font-size: 3rem;
  padding: 1rem 0rem;
}


.h2-subheading-legal {
  /* font-family: primaryBold; */
  font-size: 2rem;
  font-weight: 700;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}

.h3-subheading-legal {
  /* font-family: primarySemiBold; */
  font-weight: 700;
  font-size: 2rem;
  padding: 1rem 0rem;
}

.h3-subheadingPara-legal {
  /* font-family: primaryMedium; */
  font-weight: 500;
  font-size: 1rem;
  padding: 1rem 0rem;
}

.terms-wrapper ul {
  padding-left: 4rem;
}

.ul-listItems-legal {
  font-weight: 500;
  /* font-family: primaryMedium; */
  font-size: 1.5rem;
  padding: .5rem 0rem;
}

.custom-gradient {
  background: linear-gradient(95deg, rgba(137, 0, 255, 0.15) 0%, rgba(238, 22, 125, 0.15) 100%);
}

@media (max-width:900px) {
  .h2-subheading-legal {
      font-size: 2.5rem;
  }
  
  .h3-subheading-legal {
      font-size: 1.5rem;
  }
  
  .h3-subheadingPara-legal {
      font-size: 1.5rem;
  }
  .ul-listItems-legal {
      font-size: 1.5rem;
  }
}

.tooltip-task .tooltiptext-task {
  visibility: hidden;
  width: 370px;
  background-color: black;
  color: #fff;
  top:50%;
  margin-left: 13px;
  text-align: center;
  border-radius: 6px;
  padding: 20px 20px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  text-align: left;
}


.tooltip-task:hover .tooltiptext-task {
  visibility: visible;
}

@media (max-width: 900px) {
  .tooltip-task .tooltiptext-task {
    left: 0%;
    width: 370px;
  }
}

.leadbutton{
  font-family: 'Satoshi', sans-serif;
  font-weight:bold;
  padding:0.75rem 3rem;
  background-color:white;
  border-radius:16px;
  border:1px solid black;
  margin:2rem;
  margin-left: 0;
  }
  .leadbutton:hover{
  cursor:pointer; 
  padding:0.75rem 3rem;
  
  color:white;
  
  border-radius:0.5rem;

  box-shadow:none;
  

}
.orgbtn{
  box-shadow: 5.5px 5.5px 0px 0px #971eff;
  }
  
  .orgbtn:hover{
     border:2px solid #971eff;
     background-color:#971eff;
     color: white;
  }




  [class^="gridd-"] {
    display: grid;
    gap: 1.2em;
    column-gap: 6em;
  }
  
  @media (max-width: 512px) {
    [class^="gridd-"] {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 513px) {
    [class^="gridd-"] > * {
      grid-column: span 2;
    }
  }
  @media (min-width: 513px) and (max-width: 976px) {
    .gridd-4 {
      grid-template-columns: repeat(4, 1fr);
    }
  
    .gridd-4 > *:last-child:nth-child(odd) {
      grid-column-end: 4;
      /* background-color: #842bb0; */
    }
  }
  
  @media (min-width: 977px) and (max-width: 1025px) {
    .gridd-4 {
      grid-template-columns: repeat(6, 1fr);
    }
    .gridd-4 > *:last-child:nth-child(3n-2) {
      grid-column-end: 5;
      /* background-color: #842bb0; */
    }
    .gridd-4 > *:last-child:nth-child(3n-1) {
      grid-column-end: 6;
      /* background-color: #842bb0; */
    }
    .gridd-4 > *:nth-last-child(2):nth-child(3n + 1) {
      grid-column-end: 4;
      /* background-color: #842bb0; */
    }
  }
  @media (min-width: 1025px) {
    .gridd-4 {
      grid-template-columns: repeat(8, 1fr);
    }
    .gridd-4 > *:last-child:nth-child(4n-3) {
      grid-column-end: 6;
      /* background-color: #842bb0; */
    }
    .gridd-4 > *:last-child:nth-child(4n-2) {
      grid-column-end: 7;
      /* background-color: #842bb0; */
    }
    .gridd-4 > *:nth-last-child(2):nth-child(4n + 1) {
      grid-column-end: 5;
      /* background-color: #842bb0; */
    }
    .gridd-4 > *:last-child:nth-child(4n-1) {
      grid-column-end: 8;
      /* background-color: #842bb0; */
    }
    .gridd-4 > *:nth-last-child(3):nth-child(4n + 1) {
      grid-column-end: 4;
      /* background-color: #842bb0; */
    }
  }
  

@media (min-width: 900px) {
  .course-vimeo{
    height: 480px !important;
  }
}
 

.leadbutton{
  font-family: 'Satoshi', sans-serif;
  font-weight:bold;
  padding:0.75rem 3rem;
  background-color:white;
  border-radius:16px;
  border:1px solid black;
  margin:2rem;
  margin-left: 0;
  }
  .leadbutton:hover{
  cursor:pointer; 
  padding:0.75rem 3rem;
  
  color:white;
  
  border-radius:0.5rem;

  box-shadow:none;
  

}

.orgbtn{
  box-shadow: 5.5px 5.5px 0px 0px #971eff;
  }
  
  .orgbtn:hover{
     border:2px solid #971eff;
     background-color:#971eff;
     color: white;
  }

  .job-elem {
    border: 1px solid #9840e6;
    background: linear-gradient(95deg, rgba(137, 0, 255, 0.15) 0%, rgba(238, 22, 125, 0.15) 100%);
  }
  .line-grade {
    background: linear-gradient(90deg, #971EFF 0%, rgba(151, 30, 255, 0) 100%);
  }

  .location-title {
    background-image: linear-gradient(90deg, #971eff 0%, #ff4fb9 82.22%);
  }
  .job-line {
    top: 22%;
    position: absolute;
    border: none;
    width: 8px;
  }

  @keyframes shine {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    50% {
      transform: translateX(100%);
      opacity: 0.3;
    }
    100% {
      transform: translateX(200%);
      opacity: 0;
    }
  }

  .animate-shine {
    animation: shine 3s infinite;
    background: linear-gradient(90deg, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0.6) 50%, rgba(255,255,255,0.2) 100%);
  }